import { SearchPage } from 'Components'
import TemplateMainContent from 'Stores/franticstamper/Templates/MainContent.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { FilterChips } from 'Components'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { ContextDialog } from 'Components'
import TemplateDialog from 'Stores/_default-store/Templates/Dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchBox,
SearchBoxDialogButton,
FilterChips,
SearchResult,
SearchHeader,
ContextDialog,
SearchBoxDialog
} 
export const tplMap = {
  'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'Dialog': TemplateDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};