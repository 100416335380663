//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-50:_8898,_8410,_5361,_3060,_1784,_6528,_7088,_604;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-50')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-50', "_8898,_8410,_5361,_3060,_1784,_6528,_7088,_604");
        }
      }catch (ex) {
        console.error(ex);
      }