//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-50:_6200,_9164,_192,_1268,_1378,_6624,_6762,_3565;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-50')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-50', "_6200,_9164,_192,_1268,_1378,_6624,_6762,_3565");
        }
      }catch (ex) {
        console.error(ex);
      }