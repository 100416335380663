//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-50:_4060,_4512,_250,_4504,_8917,_6528,_1864,_7448;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-50')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-50', "_4060,_4512,_250,_4504,_8917,_6528,_1864,_7448");
        }
      }catch (ex) {
        console.error(ex);
      }