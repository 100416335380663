const searchPageUrl = '/search';

const InitFunc = () => {
  if (location.pathname === searchPageUrl) {
    document.querySelector('#searchBox').remove();
  }
};
const searchResultCallback = () => window.do_qv();

export default {
  platform: 'shift4shop',
  searchPageUrl,
  InitFunc,
  SearchRequestDefaults: {
    pageSize: 30,
  },
  facets: {
    rangedFacet: { fields: ['Price'], step: '5', name: 'priceFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { selector: '#extrapage .content', class: 'cm_main-content' },
      template: 'MainContent',
      visibleIf: {
        selector: '#cm_results',
      },
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { firstChildOf: '#leftContent', class: 'clear-self' },
      template: 'FacetPanelContainer',
      visibleIf: {
        selector: '#cm_results',
      },
    },
    {
      name: 'SearchBoxTop',
      type: 'SearchBox',
      location: '#searchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '#mob-Search' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchBoxResults',
      type: 'SearchBox',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'FilterChips',
    },
    {
      name: 'SearchResult',
      updateCallback: searchResultCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price:  High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
        'ReviewAverage:desc': 'Avg Review',
        'FreeShipping:desc': 'Free Shipping',
        'OnSale:desc': 'On Sale',
      },
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'Dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
